import Contact, { IContactContent } from './blocks/Contact'
import HowItWorks, { IHowItWorksContent } from './blocks/HowItWorks'
import Faq, { IFaqContent } from './blocks/Faq'
import TeamGrid, { ITeamGridContent } from './blocks/TeamGrid'
import FourUpHighlights, { I4UpHighlightsContent } from './blocks/4UpHighlights'
import ThreeUpHighlightCards, {
  I3UpHighlightCardsContent,
} from './blocks/3UpHighlightCards'
import Comparison, { IComparisonContent } from './blocks/Comparison'
import Features, { IFeaturesContent } from './blocks/Features'
import Locations, { ILocationsContent } from './blocks/Locations'
import Video, { IVideoContent } from './blocks/Video'
import Calculator, { ICalculatorContent } from './blocks/Calculator'
import ImageTextSplitCta, {
  IImageTextSplitCtaContent,
} from './blocks/ImageTextSplitCta'
import VideoGrid, { IVideoGridContent } from './blocks/VideoGrid'
import FeaturedHomes, { IFeaturedHomesContent } from './blocks/FeaturedHomes'
import HighlightsImageSplit, {
  IHighlightsImageSplitContent,
} from './blocks/HightlightsImageSplit'
import PressCards from './blocks/PressCards'
import CustomerValueCards from './blocks/CustomerValueCards'

type Block3UpHighlightCards = I3UpHighlightCardsContent & {
  __typename: 'Block3UpHighlightCards'
  sys: {
    id: string
  }
}

type Block4UpHighlights = I4UpHighlightsContent & {
  __typename: 'Block4UpHighlights'
  sys: {
    id: string
  }
}

type BlockCalculator = ICalculatorContent & {
  __typename: 'BlockCalculator'
  sys: {
    id: string
  }
}

type BlockComparison = IComparisonContent & {
  __typename: 'BlockComparison'
  sys: {
    id: string
  }
}

type BlockContact = IContactContent & {
  __typename: 'BlockContact'
  sys: {
    id: string
  }
}

type BlockFaq = IFaqContent & {
  __typename: 'BlockFaq'
  sys: {
    id: string
  }
}

type BlockFeatures = IFeaturesContent & {
  __typename: 'BlockFeatures'
  sys: {
    id: string
  }
}

type BlockHowItWorks = IHowItWorksContent & {
  __typename: 'BlockHowItWorks'
  sys: {
    id: string
  }
}

type BlockImageTextSplitCta = IImageTextSplitCtaContent & {
  __typename: 'BlockImageTextSplitCta'
  sys: {
    id: string
  }
}

type BlockLocations = ILocationsContent & {
  __typename: 'BlockLocations'
  sys: {
    id: string
  }
}

type BlockVideo = IVideoContent & {
  __typename: 'BlockVideo'
  sys: {
    id: string
  }
}

type BlockVideoGrid = IVideoGridContent & {
  __typename: 'BlockVideoGrid'
  sys: {
    id: string
  }
}

type BlockTeamGrid = ITeamGridContent & {
  __typename: 'BlockTeamGrid'
  sys: {
    id: string
  }
}

type BlockFeaturedHomes = IFeaturedHomesContent & {
  __typename: 'BlockFeaturedHomes'
  sys: {
    id: string
  }
}

type BlockHighlightsImageSplit = IHighlightsImageSplitContent & {
  __typename: 'BlockHighlightsImageSplit'
  sys: {
    id: string
  }
}

export type BlockMetadata = {
  trackingPosition: number
}

export type IPageBlock =
  | Block3UpHighlightCards
  | Block4UpHighlights
  | BlockCalculator
  | BlockComparison
  | BlockContact
  | BlockFaq
  | BlockFeatures
  | BlockHowItWorks
  | BlockImageTextSplitCta
  | BlockLocations
  | BlockTeamGrid
  | BlockVideo
  | BlockVideoGrid
  | BlockFeaturedHomes
  | BlockHighlightsImageSplit

interface IPageBlocksProps {
  blocks?: IPageBlock[]
  pressCards: Array<any>
  customerValueCards: Array<any>
}

const PageBlocks = (props: IPageBlocksProps) => {
  const blocks = (props.blocks ?? []).map(
    ({ __typename: type, sys, ...content }: IPageBlock, i) => {
      const trackingPosition = i + 2 // block position on page, accounting for zero-indexing & hero

      switch (type) {
        case 'Block3UpHighlightCards':
          return (
            <ThreeUpHighlightCards
              key={sys.id}
              content={content as I3UpHighlightCardsContent}
            />
          )

        case 'Block4UpHighlights':
          return (
            <FourUpHighlights
              key={sys.id}
              content={content as I4UpHighlightsContent}
            />
          )

        case 'BlockCalculator':
          return (
            <Calculator key={sys.id} content={content as ICalculatorContent} />
          )

        case 'BlockComparison':
          return (
            <Comparison key={sys.id} content={content as IComparisonContent} />
          )

        case 'BlockContact':
          return <Contact key={sys.id} content={content as IContactContent} />

        case 'BlockFaq':
          return <Faq key={sys.id} content={content as IFaqContent} />

        case 'BlockFeatures':
          return (
            <Features
              key={sys.id}
              content={content as IFeaturesContent}
              trackingPosition={trackingPosition}
            />
          )

        case 'BlockFeaturedHomes':
          return (
            <FeaturedHomes
              key={sys.id}
              content={content as IFeaturedHomesContent}
              trackingPosition={trackingPosition}
            />
          )

        case 'BlockHighlightsImageSplit':
          return (
            <HighlightsImageSplit
              key={sys.id}
              content={content as IHighlightsImageSplitContent}
            />
          )

        case 'BlockHowItWorks':
          return (
            <HowItWorks key={sys.id} content={content as IHowItWorksContent} />
          )

        case 'BlockImageTextSplitCta':
          return (
            <ImageTextSplitCta
              key={sys.id}
              content={content as IImageTextSplitCtaContent}
              trackingPosition={trackingPosition}
            />
          )

        case 'BlockLocations':
          return (
            <Locations
              key={sys.id}
              content={content as ILocationsContent}
              trackingPosition={trackingPosition}
            />
          )

        case 'BlockVideo':
          return <Video key={sys.id} content={content as IVideoContent} />

        case 'BlockVideoGrid':
          return (
            <VideoGrid key={sys.id} content={content as IVideoGridContent} />
          )

        case 'BlockTeamGrid':
          return <TeamGrid key={sys.id} content={content as ITeamGridContent} />
      }
    }
  )

  blocks.splice(1, 0, <PressCards cards={props.pressCards} key="press" />)
  blocks.splice(
    2,
    0,
    <CustomerValueCards key="customer-value" cards={props.customerValueCards} />
  )
  blocks.splice(3, 1)

  return <>{blocks}</>
}

export default PageBlocks
